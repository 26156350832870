




















import { Component, Vue } from 'vue-property-decorator'
import { RedCapital, TitleTemplatePublic, SelectedCountry } from '@/configuration'
import { mapState } from 'vuex'


@Component({
  computed: mapState(['user', 'token']),
  metaInfo: {
    title: 'Pago erroneo',
    titleTemplate: TitleTemplatePublic,
    htmlAttrs: {
      // @ts-ignore
      lang: RedCapital[SelectedCountry].Lang,
    }
  },
  data() {
    return {
      RedCapital,
      SelectedCountry,
    }
  },
})
export default class FailurePayment extends Vue {}
